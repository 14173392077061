import React, { useMemo, useEffect, useState, useRef } from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import { mergePrismicPreviewData } from "gatsby-source-prismic"
import SEO from "../components/seo"

export const pageQuery = graphql`
  query About($lang: String!) {
    prismicPage(lang: { eq: $lang }, data: { template: { eq: "about" } }) {
      ..._page
    }
  }
`

const IS_BROWSER = typeof window !== "undefined"

const About = ({ path, data: staticData }) => {
  const data = useMemo(() => {
    if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) return staticData

    return mergePrismicPreviewData({
      staticData,
      previewData: window.__PRISMIC_PREVIEW_DATA__,
    })
  }, [staticData])

  const { seo_title, seo_description, seo_image, texte } = data.prismicPage.data
  const figureRef = useRef()
  const imageRef = useRef()
  const [image, setImage] = useState(undefined)
  const [figureClass, setFigureClass] = useState()
  const [isMobile, setIsMobile] = useState(undefined)

  useEffect(() => {
    let _isMoible
    if (window.innerWidth < 1024 && "ontouchstart" in window) {
      _isMoible = true
      setIsMobile(_isMoible)
    }

    const linkWithImg = document.querySelectorAll(".about .texte a")
    console.log(linkWithImg)
    if (linkWithImg.length) {
      linkWithImg.forEach(el => {
        console.log(el.href)
        if (el.href.indexOf("images") > -1) {
          el.addEventListener("click", e => _onClick(e))
          if (!_isMoible)
            el.addEventListener("mouseenter", e => _onMouseEnter(e))
        }
      })
    }

    if (linkWithImg.length && !_isMoible) {
      setImage(linkWithImg[0].href)
    }

    const btnContact = document.querySelectorAll("span.btn-contact")
    btnContact.forEach(el => {
      el.addEventListener("click", e => _onClickContact(e))
    })

    return () => {
      if (linkWithImg.length) {
        linkWithImg.forEach(el => {
          el.removeEventListener("click", e => _onClick)
          el.removeEventListener("mouseenter", e => _onMouseEnter)
        })
      }

      if (btnContact.length) {
        btnContact.forEach(el => {
          el.removeEventListener("click", e => _onClickContact(e))
        })
      }
    }
  }, [])

  useEffect(() => {
    if (image && isMobile) {
      _resizeImageByRatio()
      // window.scroll(0, 5)
    }
  }, [image])

  const _onClick = e => {
    console.log(e)
    e.preventDefault()
    if (isMobile || window.innerWidth < 1024) {
      const img = e.target.href
      setImage(img)

      figureRef.current.addEventListener("click", _onFigureClick)
    }
    console.log("figureRef.current", figureRef)
  }

  const _onMouseEnter = e => {
    const img = e.target.href
    setImage(img)
  }

  const _onFigureClick = e => {
    console.log("_onFigureClick", e.target)
    figureRef.current.removeEventListener("click", _onFigureClick)
    setImage(null)
  }

  const _resizeImageByRatio = () => {
    // console.log("_getImageRatio",image)
    const contentHeight = window.innerHeight - 51

    const imgObj = new Image()
    imgObj.onload = () => {
      const ratio = imgObj.width / imgObj.height
      if (ratio > 1) {
        // setFigureClass('is-landscape')
        imageRef.current.style.height = (contentHeight * 45) / 100
        imageRef.current.style.width = ((contentHeight * 45) / 100) * ratio
      } else {
        // setFigureClass('is-portrait')
        imageRef.current.style.height = (contentHeight * 65) / 100
        imageRef.current.style.width = ((contentHeight * 65) / 100) * ratio
      }
      // console.log(imgObj.width, imgObj.height)
    }
    imgObj.src = image
  }

  const _onClickContact = e => {
    PubSub.publish("CONTACT", true)
  }

  return (
    <div className="page-template about">
      <SEO
        pageTitle={seo_title}
        pageDescription={seo_description.text}
        pageBanner={seo_image.url}
        template="template-about"
        pathname={path}
        page
      />

      <div className="row">
        <div className="col-md-6 col-xs-12">
          <div className="texte strong">{RichText.render(texte.raw)}</div>
          {isMobile && (
            <figure ref={figureRef} className={figureClass}>
              {image && <img src={image} alt="" ref={imageRef} />}
            </figure>
          )}
        </div>
        <div className="col-md-6 col-xs-12">
          {!isMobile && image && (
            <figure ref={figureRef}>
              <img src={image} alt="" />
            </figure>
          )}
        </div>
      </div>
    </div>
  )
}

export default About
